import { createBrowserRouter, Navigate } from "react-router-dom";
import { lazy } from "react";
import Loadable from "../components/Loadable.jsx";

const Main = Loadable(lazy(() => import("../layout/Main.jsx")));
const Auth = Loadable(lazy(() => import("../pages/Auth.jsx")));
const AddStore = Loadable(lazy(() => import("../pages/AddStore.jsx")));
const UserSettings = Loadable(lazy(() => import("../pages/UserSettings.jsx")));
const Store = Loadable(lazy(() => import("../pages/Store.jsx")));
const Products = Loadable(lazy(() => import("../pages/Products.jsx")));
const Bot = Loadable(lazy(() => import("../pages/Bot.jsx")));
const Settings = Loadable(lazy(() => import("../pages/Settings.jsx")));

export const publicRouter = createBrowserRouter([
  {
    path: "/",
    element: <Auth />,
  },
  {
    path: "/*",
    element: <Navigate to="/" />,
  },
]);

export const protectedRouter = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/addstore",
        element: <AddStore />,
      },
      {
        path: "/usersettings",
        element: <UserSettings />,
      },
      {
        path: "/console/:store_id",
        element: <Store />,
        children: [
          {
            path: "/console/:store_id/settings",
            element: <Settings />,
          },
          {
            path: "/console/:store_id/products",
            element: <Products />,
          },
          {
            path: "/console/:store_id/bot",
            element: <Bot />,
          },
        ],
      },
    ],
  },
  {
    path: "/*",
    element: <Navigate to="/" />,
  },
]);
