import axios from "axios";
import { store } from "./index";
import { toast } from "react-toastify";
import { LOGIN, LOGOUT } from "./store/actions";
import { Logout } from "./api/Auth";
import moment from "moment";
import { utils, write, read } from "xlsx";
import config from "./config/config.json";

const { IS_DEV: isDev, serverProductionUrl, PORT } = config;
const errorMessages = {
  1: "Аккаунта с таким номером не существует.",
  2: "Неверный пароль.",
  3: "Доступ запрещен.",
  4: "Срок токена авторизации истек. Повторите вход в свой аккаунт.",
  5: "Произошла неизвестная ошибка при проверке токена авторизации. Попробуйте повторно войти в свой аккаунт.",
  6: "Не удалось получить данные о подписках, сервер не вернул массив.",
  8: "Произошла ошибка в сервере.",
  9: "Неверный формат номера телефона.",
  10: "Ошибка при отправке СМС кода.",
  11: "Неверный СМС код.",
  12: "Данный пользователь уже зарегистрирован.",
  13: "Неверный формат имени пользователя.",
  14: "Неверный формат СМС кода.",
  15: "Неверный формат пароля. Длина пароля должна быть от 8 до 20.",
  16: "К сожалению, сейчас все боты заняты. Свяжитесь с нами и подпишитесь на наш инстаграм чтобы быть в курсе обновлений.",
  17: "Неверный формат названия магазина.",
  18: "Не удалось подключиться к боту. Попробуйте еще раз позже.",
  19: "Не удалось войти в Кабинет Продавца. Проверьте логин и пароль.",
  20: "Этот магазин уже зарегистрирован в системе.",
  21: "Неверный формат интервала запуска: Интервал должен быть целым числом от 0 до 1440.",
  22: "Неверный формат шага понижения цены: Шаг понижения цены должен быть целым числом от 1.",
  23: "Не удается подключиться к боту.",
  24: "Не удалось получить данные о магазине.",
  25: "Неверный формат города.",
  26: "Неверный формат списка игнорируемых магазинов.",
  27: "Неверный формат режима.",
  28: "Неверный формат настройки «Копировать первое место».",
  29: "Неверный формат настройки «Прижиматься к верху».",
  30: "Аккаунт не найден",
};

export const processError = (name, e) => {
  try {
    if (isDev) console.log(e);
    const showAlert = (name, message) =>
      toast.error(name + ": " + message, {
        draggable: false,
        closeOnClick: true,
      });
    let message = "";
    const error_code = e.response?.data?.error_code;
    message = error_code
      ? errorMessages[error_code]
      : `Не удалось подключиться к серверу.`;
    console.log(`Ошибка в "${name}":`, e.message ? e.message : "Unknown Error");
    if (e?.response?.data?.next_date) {
      const targetDate = moment(e?.response?.data?.next_date);
      const currentDate = moment();
      const duration = moment.duration(targetDate.diff(currentDate));
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      const next_time = `${
        minutes ? `${minutes} минут и ` : ""
      }${seconds} секунд.`;
      return showAlert(
        name,
        `Не удалось отправить СМС. Попробуйте позже через ${next_time}`
      );
    }
    if (e.code === "ERR_NETWORK")
      return showAlert(name, "Не удалось подключиться к серверу.");
    if (e.response?.data?.logout) {
      Logout();
    }
    showAlert(name, message);
  } catch (e) {
    console.log("Ошибка при обработке ошибки:", e);
  }
};

export const server_url = (() => {
  if (!isDev) return `${serverProductionUrl}:${PORT}`;
  const localServerAddress = window.location.host;
  return `http://${localServerAddress.split(":").shift()}:${PORT}`;
})();

export const requester = axios.create({
  baseURL: server_url,
  withCredentials: true,
});

export const run = async (setLoading = () => {}, name, callback) => {
  try {
    setLoading(true);
    try {
      await callback();
    } catch (e) {
      processError(name, e);
    } finally {
      setLoading(false);
    }
  } catch (e) {
    processError(name, e);
  }
};

export const setIsAuth = (bool) => {
  try {
    if (bool) return store.dispatch({ type: LOGIN });
    store.dispatch({ type: LOGOUT });
  } catch (e) {
    console.log("Не удалось изменить состояние isAuth:", e);
  }
};

export const getPricesBuffer = (prices) => {
  const workSheet = utils.json_to_sheet(
    prices.map((item) => ({
      sku: item.sku,
      min: item.min_price,
      max: item.max_price,
      mock: item.mock,
      preorder: item.preorder,
    }))
  );
  workSheet["!cols"] = [
    { wch: 25 },
    { wch: 40 },
    { wch: 40 },
    { wch: 40 },
    { wch: 40 },
  ];
  const workBook = utils.book_new();
  utils.book_append_sheet(workBook, workSheet, "Прайс-лист для SuperBot");
  const buffer = write(workBook, { type: "buffer" });
  return buffer;
};

export const getPricesFromFile = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const prices = utils.sheet_to_json(worksheet);
      const withEngKeys = [];
      prices.forEach((item) => {
        const preorder = parseInt(item.preorder);
        const values = {
          sku: item.sku,
          min_price: item.min,
          max_price: item.max,
          mock: item.mock,
          preorder: isNaN(preorder) ? 0 : preorder,
        };
        const isNull = Object.values(values).some(
          (value) => value === null || value === undefined
        );
        if (!isNull) {
          withEngKeys.push(values);
        }
      });
      resolve(withEngKeys);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsArrayBuffer(file);
  });
};
