import { Suspense } from "react";
import { LinearProgress, Box } from "@mui/material";

const Loader = () => (
  <Box sx={{ position: "fixed", top: 0, left: 0, zIndex: 1301, width: "100%" }}>
    <LinearProgress color="primary" />
  </Box>
);

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
